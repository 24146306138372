'use strict';

/* home.js -- Components for home */
var $ = require('jquery');
var video = require('./components/heroVideo');
var models = require('./components/videomodels');

function _registerEventHandlers() {

    $(document).ready(function () {

        video.initialize(models);

        var width = $(window).width();

        $(window).on('resize', function () {

            if ($(this).width() !== width) {
                width = $(this).width();
                $(".fb-page").toggleClass('d-none');
                setTimeout(function () { changeFBPagePlugin() }, 500);
            }
        });

        $(window).on('load', function () {
            setTimeout(function () { changeFBPagePlugin() }, 1500);
        });
    });

    function changeFBPagePlugin() {
        //getting parent box width
        var container_width = parseInt(Number($('.fb-column').width()), 10);
        var container_height = parseInt(Number($('#raffle-content').height()), 10);

        if (!isNaN(container_width)) {
            $(".fb-page").attr("data-width", container_width).attr('data-height', container_height);
        }
        if (typeof FB !== 'undefined') {
            FB.XFBML.parse();
            $(".fb-page").toggleClass('d-none');
        }
    }
}

_registerEventHandlers();